
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy, OnInit, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "../../../talk/shared/providers/broadcaster.service";
import { environment } from "../../../environments/environment";
import { Subject, takeUntil } from "rxjs";
import { CommonUtil } from "../../../talk/utils/common.util";
import { ProfileResponse } from "../../../responses/profile.response";
import { getUserProfile, RootState } from "../../../reducers";
import { Store } from "@ngrx/store";
import { ElectronService } from "app/shared/providers/electron.service";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { getAppSettings } from "app/reducers";
import { XmppService } from "app/talk/services/xmpp.service";
import { SetAppSettings } from "app/actions/app";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NotificationService } from "app/talk/services/notification.service";
import { LoggerService } from "app/shared/services/logger.service";

@Component({
  selector: "vp-about-dialog",
  templateUrl: "./about-dialog.html",
  styles: [`
  ::ng-deep vp-about-dialog .vnc-dialog_body {
    padding: 0 !important;
    height: calc(100% - 52px) !important;
    border-radius: 0 0 8px 8px;
  }
  ::ng-deep  .changelog-menu svg {
    margin-right: 8px;
  }
  ::ng-deep vp-about-dialog .vnc-dialog_footer {
    display: none !important;
  }
  ::ng-deep .vnc-switch-toggle .mat-slide-toggle-content {
    color: white !important;
  }
  `]
})
export class AboutDialogComponent implements OnDestroy, OnInit, AfterViewInit {
  isAlive$ = new Subject<boolean>();
  productInfoStyle: any = {"background-image": "url(" + CommonUtil.getFullUrl("/assets/img/Default-736h.png") + ")", "background-size": "cover"};
  currentTheme = environment.theme;
  CommonUtil = CommonUtil;
  profile: ProfileResponse;
  iconUrl: SafeResourceUrl;
  app_version: string = "4.0.8.30613";
  headerBranding: string = environment.headerBranding;
  hideChangelogMenu: boolean = environment.hideChangelogMenu;
  VERSION_AND_CHANGELOG_KEY = environment.theme === "hin" ? "VERSION_AND_CHANGELOG_HIN" : "VERSION_AND_CHANGELOG";

  isAvailableAppUpgradeCheck = false;
  isAndroidAppUpgradeEnabled = localStorage.getItem("isAndroidAppUpgradeEnabled") === "1";
  isMobileScreen: boolean;

  constructor(private broadcaster: Broadcaster,
              private changeDetectorRef: ChangeDetectorRef,
              private dialog: MatDialogRef<AboutDialogComponent>,
              private store: Store<RootState>,
              private xmppService: XmppService,
              private breakpointObserver: BreakpointObserver,
              private domSanitizer: DomSanitizer,
              private conferenceRepo: ConferenceRepository,
              private logger: LoggerService,
              private notificationService: NotificationService,
              private electronService: ElectronService) {

    this.broadcaster.on<any>("hideAboutDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialog.close();
      });

    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }


    this.iconUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/svg/vnc-products/vnctalk.svg"));
    if (this.currentTheme === "hin") {
      this.iconUrl = this.getFullUrl("/assets/VNCmail512.png");
      this.productInfoStyle = {"background-image": "url(" +
      CommonUtil.getFullUrl("/assets/img/Default-736h.png") + ")", "background-size": "cover",
      "background-position": "initial"};
    }

    if (environment.isElectron && this.app_version === "4.0.8.30613") {
      this.app_version = this.electronService.appVersion;
    }

    this.store.select(getAppSettings).pipe(takeUntil(this.isAlive$)).subscribe(options => {
      this.isAndroidAppUpgradeEnabled = options.isAndroidAppUpgradeEnabled;
    });
  }

  copyToClipboard() {
    CommonUtil.copyToClipboard([`V ${this.app_version}`]);
    this.notificationService.openSnackBarWithTranslation("COPIED_TO_CLIPBOARD", {}, 2000);
  }

  ngOnInit() {
    this.conferenceRepo.toggleHideVideoIOS(true);
    this.store.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(p => {
      this.profile = p;
    });

    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngAfterViewInit() {
    if (!!document.querySelector(".mat-slide-toggle-content")) {
      const matSliderTitle = <HTMLElement>document.querySelector(".mat-slide-toggle-content");
      if (matSliderTitle) {
        matSliderTitle.style.color = "white";
      } else {
        setTimeout(() => {
          const matSliderTitle = <HTMLElement>document.querySelector(".mat-slide-toggle-content");
          matSliderTitle.style.color = "white";
        }, 500);
      }
    }

    if  (!!document.querySelector(".mat-slide-toggle-bar")) {
      const matSliderToggleBar = <HTMLElement>document.querySelector(".mat-slide-toggle-bar");
      if (matSliderToggleBar) {
        matSliderToggleBar.style.width = "37px";
      } else {
        setTimeout(() => {
          const matSliderToggleBar = <HTMLElement>document.querySelector(".mat-slide-toggle-bar");
          if (!!matSliderToggleBar) {
            matSliderToggleBar.style.width = "37px";
          }
        }, 500);
      }
    }
  }

  deviceReady() {
    cordova.getAppVersion.getVersionNumber().then(versionNumber => {

      if (CommonUtil.isOnAndroid()) {
        // for Android we use ${VERSION}.${env.BUILD_NUMBER} as a version
        // so do not need to pass a build number since version contains both.
        this.app_version = versionNumber;
        this.changeDetectorRef.detectChanges();
      } else {
        cordova.getAppVersion.getVersionCode().then(buildNumber => {

          this.app_version = versionNumber + "." + buildNumber;
          this.changeDetectorRef.detectChanges();
        });
      }
    });

    const appPackage = "biz.vnc.vnctalk";
    appAvailability.check(
      appPackage,
      (data) => {
        this.logger.info("[about-dialog][checkifIsFromMarket] appName got data: ", appPackage, data);
        if (!!data && !!data.installSource) {
          // when installed from store, installSource is 'com.android.vending'
          if (data.installSource !== "com.android.vending") {
            this.isAvailableAppUpgradeCheck = true;
            this.changeDetectorRef.detectChanges();
          }
        }
      },
      (err) => {
        this.logger.info("[about-dialog][checkifIsFromMarket] appName got error: ", appPackage, err);
      }
    );

  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.conferenceRepo.toggleHideVideoIOS(false);
    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
    if (environment.isCordova) {
      document.removeEventListener("deviceready", this.deviceReady.bind(this));
    }
  }

  toggleAppUpdateChange() {
    this.isAndroidAppUpgradeEnabled = !this.isAndroidAppUpgradeEnabled;
    localStorage.setItem("isAndroidAppUpgradeEnabled", this.isAndroidAppUpgradeEnabled ? "1" : "0");
    this.store.select(getAppSettings).pipe(takeUntil(this.isAlive$)).subscribe(options => {
      let newOptions = options;
      newOptions.isAndroidAppUpgradeEnabled = this.isAndroidAppUpgradeEnabled;
      this.store.dispatch(new SetAppSettings(newOptions));
      this.xmppService.updatePrivateDocuments(newOptions);
    });
  }

  getFullUrl(url) {
    return this.CommonUtil.getFullUrl(url);
  }

}
